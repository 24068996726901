.selectInput {
    background: none;
    color: inherit;
    font-size: inherit;
    height: max-content;
}

/* react-multi-select-component */
div.rmsc {
    --rmsc-main: #4285f4;
    --rmsc-hover: none;
    --rmsc-selected: none;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-p: 10px; /* Spacing */
    --rmsc-radius: 4px; /* Radius */
    --rmsc-h: 38px; /* Height */
  }

.redNotification {
    background-color: rgb(160, 20, 20);
    color: white;
}

.lightGreyNotificationBody {
    background-color: #f0f0f0;
}
