.section {
    border: 2px solid #c5c5c5;
    padding: 7px;
    margin: 3px;
    width: fit-content;
}

.section > div {
    margin: 3px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.subSection {
    border: 1px dashed #c5c5c5;
    margin: 3px;
    padding: 7px;
    width: fit-content;
}
