.App {
  text-align: center;
}

.App-header {
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* font-size: calc(10px + 2vmin); */
  font-size: 1rem;
}

.App-header > div {
  margin: auto;
}

.App-link {
  color: #61dafb;
}

div.md-form {
  margin: 0;
  margin-top: 1rem;
}
